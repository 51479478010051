.contact-heading {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  text-align: center;
  font-size: 3rem;
  color: #ff3821;
}

.heading-bar {
  margin-top: 20px;
  width: 8%;
  border: none;
  border-top: 1px solid #2c0a06;
  opacity: 0.5;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.atom-bg {
  padding: 10px;
  width: 15vh;
  height: 15vh;
  cursor: pointer;
  animation: rotation 10s infinite linear;
}

.container {
  display: flex;
  justify-content: space-around;
  margin: 50px 100px;
}

.left-container {
  /* border: 2px solid black; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

.left-container h2,
.left-container h3,
.left-container p,
.right-container h2,
.right-container h3 {
  margin: 10px;
}

.left-container h2,
.right-container h2 {
  font-size: 28px;
  color: #ff3821;
}

.left-container h3 {
  margin-bottom: 30px;
}

.left-container p {
  font-size: 18px;
}

.left-container span {
  font-weight: 600;
  color: #ff3821;
}

.right-container {
  /* border: 2px solid black; */
  background-color: #ffece8;
  border-radius: 10px;
  box-shadow: 3px 3px 7px 1px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.right-container h2 {
  font-size: 40px;
  margin-bottom: 0;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.form-group small {
  color: red;
}

.form-group input,
.form-group textarea {
  margin-top: 5px;
  height: 1.5rem;
  width: 20rem;
  border: none;
  border: 2px solid #ff3821;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.form-group button {
  width: 5rem;
  height: 2rem;
  font-size: 16px;
  border: none;
  border: 2px solid #ff3821;
  border-radius: 5px;
  background: #ff3821;
  color: white;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #bd2b1b;
  border: 2px solid #bd2b1b;
}

.form-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-success img {
  width: 100px;
}

.form-success h4 {
  color: #ff3821;
  margin: 0;
  margin-top: 20px;
  font-size: 20px;
}

.form-success h5 {
  color: #2c0a06;
  margin: 0;
  margin-top: 5px;
  font-size: 16px;
}

.location-container {
  background-color: #ffece8;
  padding: 20px;
}

.location {
  margin-top: 50px;
  height: 500px;
  border: 2px solid #ff3821;
  border-radius: 10px;
}

.map {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 10px;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2rem;
  }

  .container {
    flex-direction: column;
    margin: 30px 20px;
    margin-bottom: 50px;
  }
  .left-container {
    width: 100%;
  }
  .right-container {
    margin-top: 5rem;
    width: 80%;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
  }
}
