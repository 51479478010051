.header {
  display: flex;
  justify-content: space-around;
  height: 110px;
}

.shadow {
  background-color: white;
  box-shadow: 1px 0 5px 3px rgb(208, 208, 208);
  margin-bottom: 5px;
}

.brand img {
  margin-top: 20px;
  width: 220px;
}

.hamburger {
  position: absolute;
  top: 3rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  width: 100%;
  /* background-color: white; */
  border-radius: 10px;
}

.white {
  background-color: white;
}

.orange {
  background-color: #ff3821;
}

.nav-link-div {
  display: flex;
  align-items: center;
  padding: 20px;
}

.cross {
  display: none;
}

.nav-links {
  list-style-type: none;
  display: flex;
  flex-direction: row;
}

.nav-links .li {
  margin: 10px 10px;
  padding: 10px;
  font-size: 18px;
  border-top: 3px solid transparent;
  cursor: pointer;
  text-decoration: none;
}

.nav-links .home-link-style {
  color: white;
}

.nav-links .link-style {
  color: rgba(0, 0, 0, 0.7);
}

.nav-links .home-quote-link {
  border: 3px solid #ff3821;
  border-radius: 5px;
}

.nav-links .quote-link {
  border: 3px solid #ff3821;
  border-radius: 5px;
}

.nav-links .home-link-style:hover {
  border-top: 3px solid #ff3821;
}

.nav-links .link-style:hover {
  border-top: 3px solid #ff3821;
}

.nav-links .home-quote-link:hover {
  background-color: #ff3821;
}

.nav-links .quote-link:hover {
  background-color: #ff3821;
  color: white;
}

.dropdown {
  float: left;
}

.dropdown-content {
  margin-top: 0.5rem;
  display: none;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #ff3821;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  color: #691910;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 900px) {
  .header {
    flex-direction: column;
  }

  .brand img {
    margin-left: 20px;
    width: 150px;
  }

  .nav-links {
    flex-direction: column;
    width: 9rem;
  }

  .nav-link-div {
    display: none;
  }

  .nav-links .home-link-style,
  .nav-links .link-style {
    margin: 0;
    color: white;
  }

  .dropdown-content {
    position: relative;
  }

  .hamburger {
    display: flex;
  }

  .nav-link-div.active {
    display: flex;
    align-items: flex-start;
    z-index: 2;
    background-color: #691910;
    width: 60%;
    padding: 20px;
    position: absolute;
    top: 0;
    height: 100vh;
  }

  .nav-link-div.active .cross {
    display: block;
    color: white;
    position: relative;
    left: 200px;
    text-align: right;
    cursor: pointer;
  }
}
