.whoweare-bg {
  background: url(../../assets/Tortoise-Shell.svg);
}
.whoweare,
.history {
  padding: 5% 20%;
  text-align: justify;
}

.history {
  background: url(../../assets/Tortoise-Shell\ opposite.svg);
}

.whoweare p,
.history p {
  font-size: 20px;
  color: rgb(86, 86, 86);
  line-height: 35px;
}

.lab-break {
  height: 30vh;
  width: 100%;
  margin: 0;
}

@media (max-width: 900px) {
  .whoweare,
  .history {
    padding: 20px;
  }
}
