.q-container {
  margin: 20px 10%;
  display: flex;
  justify-content: space-around;
}

.q-container h1 {
  font-weight: 600;
}

.q-left-container h3 {
  text-align: center;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

.q-form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
}

.q-form-group input,
.q-form-group textarea {
  margin-top: 5px;
  height: 1.5rem;
  width: 100%;
  border: none;
  border-bottom: 2px solid #ff3821;
  padding: 5px;
  font-size: 16px;
}

.q-form-group input:focus,
.q-form-group textarea:focus {
  outline: none;
}

.q-form-group button {
  width: 25%;
  height: 2rem;
  font-size: 16px;
  border: none;
  border: 2px solid #ff3821;
  border-radius: 5px;
  background: #ff3821;
  color: white;
  cursor: pointer;
}

.q-form-group button:hover {
  background-color: #bd2b1b;
  border: 2px solid #bd2b1b;
}

.q-form-group small {
  color: red;
}

.q-right-container {
  /* border: 2px solid #ff3821; */
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.manQuote {
  width: 25rem;
}

@media (max-width: 900px) {
  .q-container {
    flex-direction: column;
  }

  .manQuote {
    width: 15rem;
  }
}
