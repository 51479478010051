.heartBg {
  display: inline-flex;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -3;
  width: 100%;
  height: 120%;
  opacity: 0.2;
}

.features {
  margin: 30px 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.feature {
  padding: 20px;
  /* margin: 20px; */
  text-align: center;
}

.feature p {
  font-size: 28px;
  font-weight: 700;
  color: #ff3821;
}

.feature img {
  width: 80px;
  margin: 0;
}

@media (max-width: 900px) {
  .sf-container {
    background-color: #ffece8;
  }

  .features {
    display: flex;
    flex-direction: column;
    margin: 30px 30px;
  }

  .heartBg {
    display: none;
  }

  .feature {
    box-shadow: 1px 1px 2px 2px rgb(182, 182, 182);
    margin: 20px 0;
    border-radius: 10px;
    background-color: white;
  }

  .feature p {
    font-size: 24px;
    margin-bottom: 0;
  }
}
