.wu-box {
  margin: 50px 10%;
  padding: 30px;
  display: flex;
  justify-content: space-around;
  box-shadow: 2px 2px 5px 2px rgb(182, 182, 182);
  border-radius: 10px;
  background-color: #ffece8;
}

.content {
  padding: 30px;
}

.content h1 {
  text-align: start;
}

.content p {
  font-size: 20px;
  text-align: justify;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  width: 200px;
}

@media (max-width: 900px) {
  .wu-box {
    flex-direction: column;
    padding: 20px 5px;
  }

  .content h1 {
    font-size: 30px;
  }
}
