.pnf-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 150px;
}

.pnf-img {
  width: 300px;
}

.pnf-content {
  display: flex;
}

.pnf-heading-1 {
  color: rgb(68, 68, 68);
  margin: 0;
  align-self: center;
  font-size: 30px;
}

.pnf-heading-2 {
  color: rgb(91, 91, 91);
  margin: 0;
  align-self: center;
  font-size: 18px;
  padding-top: 5px;
  padding-left: 5px;
}

@media (max-width: 900px) {
  .pnf-container {
    margin: 100px 10px;
  }
}
