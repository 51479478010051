.pp-container {
  display: flex;
  justify-content: center;
  margin: 50px;
}

.pp-left-container {
  text-align: center;
  width: 50%;
}

.pp-left-container img {
  max-height: 500px;
}

.pp-right-container {
  width: 50%;
  margin-left: 5rem;
}

.pp-heading {
  text-align: start;
}

.pp-right-container .pp-model {
  margin-top: 2px;
  font-size: 18px;
  color: gray;
}

.pp-right-container .pp-description {
  font-size: 20px;
  color: rgb(76, 76, 76);
  text-align: justify;
}

.pp-break {
  border: 0;
  border-top: 1px solid rgb(186, 186, 186);
}

.pp-table {
  width: 70%;
}

.pp-btn {
  margin-top: 20px;
  display: block;
  width: 7rem;
  height: 2.3rem;
  font-size: 18px;
  border: none;
  outline: none;
  border: 2px solid #ff3821;
  background-color: #ff3821;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.pp-btn .pp-btn-link {
  color: white;
  text-decoration: none;
}

.pp-btn:hover {
  background-color: #e9321e;
}

.pp-btn:active {
  transform: scale(0.9);
}

@media (max-width: 900px) {
  .pp-container {
    flex-direction: column;
    margin: 0;
    padding: 30px;
  }

  .pp-left-container,
  .pp-right-container {
    width: 90%;
    margin: 20px 0;
  }

  .pp-left-container img {
    width: 250px;
    margin: 0;
  }

  .pp-table {
    width: 100%;
  }
}
