.p-heading-div {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-product-list {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.p-product {
  border: 2px solid #ff3821;
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 250px;
  /* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2); */
}

.p-product-imgdiv {
  height: 350px;
  display: flex;
}

.p-product-image {
  width: 200px;
  max-height: 300px;
  padding: 20px;
  margin-bottom: 20px;
  align-self: center;
}

.p-product-body {
  padding-left: 5px;
  border-left: 1px solid #ff3821;
}

.p-product-body h2 {
  color: #2c0a06;
  font-size: 30px;
  margin: 0;
}

.p-product-body p {
  color: #2c0a06;
  margin: 0;
}

.p-product-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.enquiry-btn {
  width: 5rem;
  height: 1.75rem;
  border: none;
  border: 2px solid #ff3821;
  background-color: #ff3821;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.info-btn {
  width: 5rem;
  height: 1.75rem;
  border: none;
  border: 2px solid #ff3821;
  background-color: transparent;
  color: #ff3821;
  font-size: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.enquiry-btn:hover,
.info-btn:hover {
  transform: scale(1.1);
}
