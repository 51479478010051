.homefront,
.second-div {
  display: flex;
  justify-content: space-around;
}

.bg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  z-index: -1;
}

.homefront {
  margin: 40px 50px;
}

.right,
.left {
  width: 50%;
  margin: 3%;
}

.homefront .left {
  text-align: center;
}

.homefront .right {
  display: flex;
  flex-direction: column;
  padding-left: 10%;
}

.typewrite-heading {
  display: flex;
  flex-direction: column;
}

.homefront .right p {
  padding: 0;
  margin-top: 8%;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.homefront .right h1 {
  text-align: left;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 45px;
}

.homefront .homefront-img {
  width: 30vw;
  height: 30vw;
}

.homefront-btn {
  margin-top: 60px;
  width: 10rem;
  height: 3rem;
  border: none;
  border: 3px solid white;
  background-color: transparent;
  color: white;
  font-size: 1.25rem;
  font-weight: bolder;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.homefront-btn:hover {
  color: #ff3821;
  background-color: white;
}

.homefront-btn:active {
  transform: scale(0.9);
}

/* SECOND DIV */

.about-heading {
  margin-top: -100px;
  margin-bottom: 0;
  text-align: center;
  font-size: 3rem;
  color: #ff3821;
}

.heading-bar {
  margin-top: 20px;
  width: 8%;
  border: none;
  border-top: 1px solid #2c0a06;
  opacity: 0.5;
  z-index: 0;
}

.second-div {
  align-items: center;
  margin-top: 0;
  padding: 0;
}

.second-div .left {
  padding: 0% 3%;
  font-size: 23px;
  line-height: 40px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  text-align: justify;
}

.second-div .bg-blob {
  display: block;
  width: 600px;
  position: absolute;
  left: 100px;
  top: 670px;
  z-index: -1;
}

.second-div .left p span {
  font-weight: 600;
  color: #ff3821;
}

.second-div .right {
  text-align: center;
}

.second-div .right img {
  width: 300px;
  position: relative;
  top: -50px;
}

.breaker-img .lab {
  width: 100%;
}

/* THIRD DIV */

.third-div {
  text-align: center;
}

.third-div h2,
.sixth-div h2 {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
  font-size: 3rem;
  color: #ff3821;
}

.product-list {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product {
  /* border: 2px solid #ff3821; */
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  transition: all 1s ease;
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.product-imgdiv {
  height: 350px;
  display: flex;
}

.product img {
  width: 200px;
  padding: 20px;
  max-height: 300px;
}

.product-body {
  padding-left: 5px;
  border-left: 1px solid #ff3821;
  text-align: start;
  margin-bottom: 20px;
}

.product-body h2 {
  color: #2c0a06;
  font-size: 30px;
  text-align: start;
  margin: 0;
}

.product-body p {
  color: #2c0a06;
  margin: 0;
}

.product-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.enquiry-btn {
  width: 5rem;
  height: 1.75rem;
  border: none;
  border: 2px solid #ff3821;
  background-color: #ff3821;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.info-btn {
  width: 5rem;
  height: 1.75rem;
  border: none;
  border: 2px solid #ff3821;
  background-color: transparent;
  color: #ff3821;
  font-size: 15px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.enquiry-btn:hover,
.info-btn:hover {
  transform: scale(1.1);
}

/* fourth div */

.fourth-div {
  text-align: center;
  background-color: #ffece8;
  padding-top: 30px;
  padding-bottom: 30px;
}

.fourth-div p {
  font-size: 25px;
  font-weight: 700;
  padding: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.fourth-div button {
  margin-bottom: 18px;
  width: 7rem;
  height: 2.5rem;
  border: none;
  border: 3px solid #ff3821;
  background-color: #ff3821;
  color: #ffece8;
  font-size: 1.25rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.fourth-div button:active {
  transform: scale(0.9);
}

/* fifth div */

.fifth-div {
  background: url(https://firebasestorage.googleapis.com/v0/b/axeemlab-2752d.appspot.com/o/assets%2FBg.jpg?alt=media&token=7c322c21-c2a9-4fe6-9884-825ba011a09a);
  background-size: cover;
  background-attachment: fixed;
  margin: 60px 5%;
  border-radius: 10px;
}

.card-grid {
  padding: 100px 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card {
  margin: 20px;
  padding: 20px;
  text-align: justify;
  width: 350px;
  /* border: 2px solid #ff3821; */
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #ffece8;
  color: rgba(0, 0, 0, 0.7);
}

.card-title {
  margin-bottom: 0;
  font-size: 2rem;
  color: #ff3821;
}

.card-body {
  height: 120px;
  display: flex;
  align-items: center;
}

.card-btn {
  margin-bottom: 20px;
  width: 7rem;
  height: 2.5rem;
  border: none;
  border: 3px solid #ff3821;
  background-color: transparent;
  color: #ff3821;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.card-btn:hover {
  color: #ffece8;
  background-color: #ff3821;
}

/* Sixth div */

.sixth-div {
  background-color: #ffece8;
  padding: 20px;
}

.sixth-div .img-div {
  margin: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.sixth-div .img-div-img {
  width: 150px;
  margin: 10px;
}

@media screen and (max-width: 900px) {
  .homefront,
  .second-div {
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }

  .bg {
    /* display: none; */
    object-fit: cover;
    object-position: right;
  }

  .left,
  .right {
    width: 90%;
    margin: 0;
  }

  .second-div .left p {
    font-size: 20px;
  }

  .homefront .right {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-top: 20px;
  }

  .homefront-btn {
    width: 6rem;
    height: 2rem;
    font-size: 1rem;
    /* color: #ff3821;
    border: 2px solid #ff3821; */
    margin-top: 50px;
  }

  .typewrite-heading {
    text-align: center;
  }

  .homefront .right p {
    color: rgba(255, 255, 255, 0.5);
    padding: 1% 0;
    margin-top: 0;
    font-size: 15px;
  }

  .homefront .right h1 {
    padding: 0;
    margin: 0;
    /* color: #ff3821; */
    font-size: 2rem;
  }

  .homefront .homefront-img {
    width: 15rem;
    height: 15rem;
  }

  .third-div h2,
  .sixth-div h2 {
    font-size: 2rem;
  }

  .about-heading {
    margin-top: 80px;
    font-size: 2rem;
  }

  .card-body {
    height: auto;
  }

  .sixth-div .img-div-img {
    width: 100px;
  }
}
