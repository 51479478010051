.footer {
  width: 100%;
  height: auto;
  position: absolute;
  background-color: #2c0a06;
}

.footer-inner {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  margin: 2% 5%;
  color: white;
}

.brand,
.quick-links,
.address {
  width: 22%;
}

.footer p {
  margin: 10px;
}

.footer-inner h4 {
  font-size: 22px;
}

.trademark {
  margin-top: 30px;
}

.trademark p,
.trademark h4 {
  margin: 5px;
}

.quick-links div {
  display: flex;
  flex-direction: column;
}

.quick-links h4,
.address h4 {
  margin-bottom: 5px;
}

.quick-links .p {
  color: white;
  text-decoration: none;
  cursor: pointer;
  width: 70px;
  margin: 5px;
}

.quick-links .p:hover {
  opacity: 0.6;
}

.address p {
  margin-left: 5px;
}

.footer-end {
  /* background-color: white; */
  color: #bbb;
  font-size: 14px;
  margin-left: 145px;
  padding: 10px;
}

.footer-end a {
  color: #bbb;
  /* text-decoration: n; */
}

.footer hr {
  margin: 5px 150px;
  border: none;
  border-top: 1px #bbb solid;
  opacity: 0.5;
}

.scroll-btn {
  position: absolute;
  bottom: 50px;
  right: 50px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  color: #ff3821;
  font-size: 35px;
  transition: all 0.2s ease-in-out;
}

.scroll-btn:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 900px) {
  .footer-inner {
    flex-direction: column;
    margin-top: 20px;
  }

  .brand,
  .quick-links,
  .address {
    width: 80%;
  }

  .footer-end {
    margin-left: 15px;
    padding: 10px;
  }

  .footer hr {
    margin: 5px 25px;
  }
}
