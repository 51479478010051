.hry-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 15%;
}

.hry-left-container {
  background-color: #ffece8;
  /* border: 2px solid black; */
  padding: 5%;
  width: 45%;
  margin-right: -50px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hry-heading-bar {
  width: 60%;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 20px;
  border: none;
  border-top: 2px solid #ffb4a4;
  opacity: 0.5;
}

.hry-left-container p {
  text-align: justify;
  font-size: 17px;
  line-height: 2rem;
  color: rgb(27, 27, 27);
}

.hry-left-container p span {
  font-weight: 600;
}

.hry-btn {
  margin: 18px 0;
  width: 7rem;
  height: 2.5rem;
  border: none;
  border: 3px solid #ff3821;
  background-color: #ff3821;
  color: #ffece8;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.hry-btn:active {
  transform: scale(0.9);
}

.hry-right-container {
  /* border: 2px solid #ff3821; */
  width: 55%;
  z-index: -1;
}

.hry-right-container img {
  width: 38vw;
  height: 100%;
}

@media (max-width: 900px) {
  .hry-container {
    flex-direction: column;
  }

  .hry-left-container {
    width: 100%;
    margin-right: 0;
  }

  .hry-left-container h1 {
    text-align: start;
    font-size: 40px;
  }

  .hry-right-container {
    width: 100%;
    margin: 0;
  }

  .hry-right-container img {
    width: 100%;
    height: 1200px;
    margin: 0;
    position: absolute;
    top: 110px;
    left: 0;
    z-index: -4;
  }
}
