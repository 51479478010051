.image-gallery {
  height: 500px;
  border: 2px solid #ff3821;
  border-radius: 10px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
  padding: 10px 30px;
  align-items: center;
}

.image-gallery p {
  cursor: pointer;
  font-size: 50px;
  font-weight: 700;
  color: rgb(159, 172, 184);
}

.image-gallery img {
  height: 90%;
}

@media (max-width: 900px) {
  .image-gallery {
    height: auto;
    padding: 10px 10px;
  }

  .image-gallery p {
    font-size: 40px;
    font-weight: 300;
  }

  .image-gallery img {
    width: 80%;
    height: auto;
  }
}
